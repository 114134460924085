// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="footer bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 h-100 text-center text-lg-start my-auto">
            {/* Footer content */}
          </div>
          <div className="col-lg-6 h-100 text-center text-lg-end my-auto">
            {/* Footer content */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

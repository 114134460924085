import React from 'react';
import './Masterhead.css'; 

const Masterhead: React.FC = () => {
  return (
    <div className="header-container">
      <img src="assets/img/line_tech_futuristic.png" alt="Background" className="background-image" />
      <h1 className="title">/BonaTec.</h1>
      <div className="subtitle-container">
        <span className="subtitle">SOLUCIONES INFORMÁTICAS</span>
      </div>
    </div>
  );
};

export default Masterhead;
